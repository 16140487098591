import * as React from "react"
import {AppLayout} from "../layouts"
import {useApp} from "../contexts/appContext"
import {Button, Loader} from "../components";
import services, {ServiceType, persons, PersonType} from "../data/services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import PersonItem from "../components/Person/PersonItem";
import {useNavigate} from "react-router-dom";

type TargetType = 'service' | 'person'

export default function CheckPointScreen() {

    const {labels} = useApp();

    const [service, setService] = React.useState<ServiceType | undefined>(undefined);
    const [target, setTarget] = React.useState<TargetType | null>('service');
    const [loading, isLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {

    }, [])

    const selectServiceHandler = (service: ServiceType) => {

        console.log('selectServiceHandler', service);

        setService(service);

        setTarget('person');
    };

    const selectPersonHandler = (person: PersonType) => {

        isLoading(true);

        setTimeout(() => {
            navigate('/sent', { replace: true });
        }, 4000);

        console.log('selectPersonHandler', person);

    };

    const targetIsService = () => target === 'service';
    const targetIsPersonal = () => target === 'person';

    const goToService = () => setTarget('service')

    return (
        <AppLayout>
            <React.Fragment>
                <div className='px-4 py-5'>

                    {loading && (
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <Loader />
                            <p>
                                <i>Traitement de votre demande ...</i>
                            </p>
                        </div>
                    )}

                    {! loading && (
                        <>
                            <h3 className='text-uppercase text-center mb-4'>{labels.welcome}</h3>
                            { targetIsService() && (
                                <>
                                    <p>Sélectionner un service</p>
                                    {services.map((item: ServiceType) => (
                                        <Button key={item.id} title={item.name} py={5} onClick={() => selectServiceHandler(item)} theme={item.theme} />
                                    ))}
                                </>
                            )}

                            { targetIsPersonal() && service && (
                                <>
                                    <button className='btn btn-dark' onClick={goToService}>
                                        {/*@ts-ignore*/}
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        <span className='px-2'>Retour au service</span>
                                    </button>

                                    <p>Choisissez la personne avec qui vous avez un rendez-vous au <b>{service.name}</b></p>

                                    {persons.map((item: PersonType) => (
                                        <div className='mb-4'>
                                            <PersonItem key={item.id} {...item} onSelect={() => selectPersonHandler(item)} />

                                            <div className='d-flex flex-column'>
                                                <button className={`btn btn-${service.theme} py-2`} onClick={() => selectPersonHandler(item)}>
                                                    <span className='px-2 text-uppercase'>Annoncez-vous</span>
                                                    {/*@ts-ignore*/}
                                                    <FontAwesomeIcon icon={faBell} />
                                                </button>
                                            </div>
                                            <hr/>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}

                </div>
            </React.Fragment>
        </AppLayout>
    );
}
