import React from "react";
import {PersonType} from "../../data/services";



export default function PersonItem({id, full_name, image, role, onSelect}: PersonType) {
    return <div className='card p-2 mb-2'>

        <img src={`/${image}`} className='image img-fluid mb-2'/>

        <h6>
            <b>{full_name}</b>
        </h6>

        <p>{role}</p>

    </div>
}
