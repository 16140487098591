export type ServiceType = {
    id: number,
    name: string,
    theme: string
}

export type PersonType = {
    id: number,
    full_name: string,
    image?: string
    role?: string
    onSelect?: (item: PersonType) => void
}

const services : ServiceType[] = [
    {
        id: 1,
        name: "Service comptabilité",
        theme: 'danger'
    },

    {
        id: 2,
        name: "Service Juridique",
        theme: 'primary'
    },

    {
        id: 3,
        name: "Service Ressources humaines",
        theme: 'info'
    },

    {
        id: 4,
        name: "Service Recrutement",
        theme: 'warning'
    },
]

export const persons : PersonType[] = [
    {
        id: 1,
        full_name: "Mr Lionel TOURE",
        image: 'man.jpeg',
        role: 'Directeur'
    },

    {
        id: 2,
        full_name: "Mr Christian TCHOUANGUE",
        image: 'man.jpeg',
        role: 'Responsable commercial'
    },

    {
        id: 3,
        full_name: "Mr Herman FOSSO",
        image: 'man.jpeg',
        role: 'Responsable financier'
    },

    {
        id: 4,
        full_name: "Mme Adèle NANA",
        image: 'woman.jpeg',
        role: 'Chargé de recrutement'
    },

    {
        id: 5,
        full_name: "Mme Sylviane FOKOU",
        image: 'woman.jpeg',
        role: 'Trésorière'
    },
]

export default services
