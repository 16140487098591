import * as React from "react"
import {GuestLayout} from "../layouts"
import {useNavigate} from "react-router-dom"

/**
 * Page de lancement de l'application
 * Au bout de 2 seconde l'utilisateur est rediriger vers la page de téléchargement de ses pièces (permis & CNI)
 *
 * @constructor
 */
export default function LandingScreen() {

    const navigate = useNavigate()
    const timeout = 2000

    React.useEffect(() => {
        setTimeout(() => {
            navigate('/checkpoint', { replace: true })
        }, timeout)

    }, [])

    return (
        <GuestLayout>
            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                <h3 className='text-dark text-uppercase'>Annoncez votre présence</h3>
                <h6>Si vous avec un rendez-vous</h6>
            </div>
        </GuestLayout>
    );
}
