import * as React from "react";
import {GuestLayout} from "../layouts"
import {Link,} from "react-router-dom";

export default function AlertScreen() {

    return (
        <GuestLayout>
            <div className='px-4 text-center d-flex flex-column w-100 align-items-center justify-content-center h-100 base-app'>
                <img src='/sent.png' width={100} />

                <br/>
                <h3>Nous avons informé le concerné de votre présence :)</h3>
                <br/>

                <button className='btn btn-info btn-lg'>
                    <Link to={'/checkpoint'}>
                        <strong className='small text-uppercase'>
                            Retour à l'accueil
                        </strong>
                    </Link>
                </button>
            </div>
        </GuestLayout>
    );
}
