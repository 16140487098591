import axios from "axios"

axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"

// Point d'entrée de Web Service
const URL = 'https://rpa.jlrmobility.com/api'
//const URL = 'https://jlrmobility.test/api'

const axiosInstance = axios.create({
    baseURL: URL,
});

function storeImages(controller, formFiles) {
    return axiosInstance.post('/data-cap/images/store', formFiles, {signal: controller?.signal})
}

/**
 * Retourne les informations contenues dans les différentes images
 *
 * @param controller
 * @param formFiles
 * @returns {Promise<AxiosResponse<any>>}
 */
function readImagesData(controller, formFiles) {
    return axiosInstance.post('/data-cap/images/read-data', formFiles, {signal: controller?.signal})
}

/**
 * Envoie les données saisies par l'utilisateur
 *
 * @param controller
 * @param formData
 * @returns {Promise<AxiosResponse<any>>}
 */
function sendForm(controller, formData) {
    return axiosInstance.post('/rpa/send-form', formData, {signal: controller?.signal})
}

/**
 * Récupère les informations pour la jauge de progression du téléchargement
 *
 * @param token
 * @param controller
 * @returns {Promise<AxiosResponse<any>>}
 */
function getStepper(token, controller) {
    return axiosInstance.get('/data-cap/stepper?token='+token, {signal: controller?.signal})
}

export {
    storeImages,
    readImagesData,
    sendForm,
    getStepper
}
