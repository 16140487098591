import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from "react";

export default function Loader({size = 2}: {size?: number}) {
    return <p className='d-flex justify-content-start'>
        {/*@ts-ignore*/}
        <FontAwesomeIcon className={`fa-spin fa-${size}x`} icon={faSpinner} />
    </p>
}
